import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import QRIcon from '@mui/icons-material/QrCode';
import ContactsRoundedIcon from '@mui/icons-material/ContactsRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';


import ErrorIcon from '@mui/icons-material/Error';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepIcon, { StepIconProps } from '@mui/material/StepIcon';

import PersonInfoPage from '../pages/PersonInfoPage';
import { StepContent, Typography } from '@mui/material';
import DeviceInfoPage from '../pages/DeviceInfoPage';
import ResultadoInfoPage from '../pages/ResultadoInfoPage';

// const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
//   [`&.${stepConnectorClasses.alternativeLabel}`]: {
//     top: 22,
//   },
//   [`&.${stepConnectorClasses.active}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       backgroundImage:
//         'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
//     },
//   },
//   [`&.${stepConnectorClasses.completed}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       backgroundImage:
//         'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
//     },
//   },
//   [`& .${stepConnectorClasses.line}`]: {
//     height: 3,
//     border: 0,
//     backgroundColor: '#eaeaf0',
//     borderRadius: 1,
//     ...theme.applyStyles('dark', {
//       backgroundColor: theme.palette.grey[800],
//     }),
//   },
// }));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme }) => ({
  backgroundColor: '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',

  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        backgroundImage:
          'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      },
    },
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
        borderColor: 'primary.300',
        color: 'primary.300',
        backgroundImage:
        
          'linear-gradient( 136deg, rgb(182, 244, 146) 0%, rgb(51, 139, 147) 50%, rgb(138,35,135) 100%)',
      },
    },
  ],
}));



function ColorlibStepIcon(props: StepIconProps & { result?: string }) {
  const { active, completed, icon, className, result } = props;
 
  const icons: { [index: string]: React.ReactElement<any> } = {
    1: <QRIcon />,
    2: <ContactsRoundedIcon />,
    3: result === 'undefined' ? <QuestionMarkIcon /> : result==='success'? <CheckCircleIcon />: <ErrorIcon />, 
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {/* {completed && (<CheckCircleIcon/>)} */}
      {( icons[String(icon)])}
    </ColorlibStepIconRoot>
  );
}



function _renderStepContent(step: number,codigo:string, result:string) {
    switch (step) {
      case 0:
        return <DeviceInfoPage codigo={codigo}  />;
      case 1:
        return <PersonInfoPage codigo={codigo}  />;
      case 2:
        return <ResultadoInfoPage status={result}  />;
  
      default:
        return <div>Not Found</div>;
    }
  }

export default function ScannFlow() {
  const [codigo, setCodigo] = useState<string>(''); // Buffer para almacenar el código de barras
  const [result, setResult] = useState<string>('undefined'); // El resultado del proceso (success o failure)
  const [activeStep, setActiveStep] = useState<number>(0); // El resultado del proceso (success o failure)
  const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});
  const [steps, setSteps] =  useState(['Escaneo QR', 'Escaneo identificación']); // El resultado del proceso (success o failure)
 
 // const bufferRef = useRef<string>(''); // Referencia para el código acumulado


  // Función que procesa el código una vez completado
  const selectStep = (codigo: string) => {
    setCodigo((prev)=>codigo);
    if (codigo.length < 5) {
      debugger;

      setSteps((oldArray) => oldArray.length==3 ?oldArray.slice(0, -1): oldArray);

      handleStep(1)();
      

      setResult((prev)=>'unfedined');

    } else if (codigo.length >=5) {
      handleStep(3)();

      setResult((prev)=>'success');
      setSteps(oldArray => [...oldArray,'Resultado'] );



    } else {
      handleStep(3)();
      setResult((prev)=>'success');
     // handleNext();
    }
  };




  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
  
    setActiveStep((prevStep) => prevStep + 1);

  };

  // const handleBack = () => {
  //   setActiveStep((prevStep) => prevStep -1);
  // };

  const handleStep = (step: number) => () => {

    setActiveStep((prevStep) => step);

  };

  const handleComplete = () => {
    debugger;
    console.log(JSON.stringify(completed));
    setCompleted({
      ...completed,
      [activeStep]: true,
    });
    handleNext();
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  //   setCompleted({});
  // };



  useEffect(() => {
    let buffer = ''; // Variable para acumular las teclas presionadas

    const handleKeyDown = (event: KeyboardEvent) => {
      // Si la tecla es "Enter", procesamos el código de barras
      if (event.key === 'Enter') {
        console.log(`Código de barras leído: ${codigo}`);
       // setCodigo((prev)=>buffer); // Actualizar el estado con el código leído
        console.log('el codigo' + buffer);
       // bufferRef.current = buffer; // Actualizar la referencia del buffer
        debugger;
        selectStep(buffer); // Pasamos el buffer actualizado a selectStep

    
        buffer = ''; // Limpiar el buffer
      } else {
       // setCodigo((prev)=>prev+event.key);
        // Acumular las teclas presionadas (código de barras)
        buffer += event.key;
      }
    };

    // Agregar el listener de teclado
    window.addEventListener('keydown', handleKeyDown);

    //Limpiar el listener cuando el componente se desmonte
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // Se ejecuta una vez al montar el componente

  

  return (
    <>
     <Stepper orientation="vertical" activeStep={activeStep}  >
          {steps.map((label, index) => {
            return (
              <Step key={label} active={true} completed={completed[index]}>
                <StepLabel StepIconComponent={(props) => <ColorlibStepIcon {...props} result={result} />}>
                    {label}
                </StepLabel>

 
                <StepContent>{_renderStepContent(index, codigo,result)}</StepContent>
  
              </Step>
            );
          })}
        </Stepper>

    </>
  );
}
