import React from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';

interface PersonInfoPageProps {
  codigo: string; // El código que pasaremos como prop
}

export default function PersonInfoPage({ codigo }: PersonInfoPageProps) {
  if (!codigo) {
    return (

      <Card sx={{  boxShadow: 3, borderRadius: 2 }}>
        <CardContent sx={{ textAlign: 'center' }}>
          <Typography variant="h5" component="div" gutterBottom>
            Escanea el código
          </Typography>
          
          <Typography variant="body1" sx={{ marginBottom: '20px' }}>
            Por favor, escanee la identificación de la persona
          </Typography>
        
        </CardContent>
      </Card>

    );
  }

  return (
    
      <Card sx={{ minWidth: 275 }} elevation={4}>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },  // Columna en pantallas pequeñas, fila en pantallas medianas y grandes
              justifyContent: 'space-between',
              gap: 2  // Espaciado entre las columnas
            }}
          >
            {/* Primer bloque: Código del dispositivo */}
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>
                Nombre y Apellido
              </Typography>
              <Typography variant="h5" component="div">
                {codigo}
              </Typography>
            </Box>
  
            {/* Segundo bloque: Tipo dispositivo */}
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>
                Fecha de Nacimiento
              </Typography>
              <Typography variant="h5" component="div">
                {'10/10/1981'}
              </Typography>
            </Box>

            {/* Tercer bloque: Tipo dispositivo */}
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>
                Edad
              </Typography>
              <Typography variant="h5" component="div">
                {'43'}
              </Typography>
            </Box>
            
          </Box>
        </CardContent>
      </Card>
  );
}
