import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface ResultadoInfoPageProps {
  status: string; // El código que pasaremos como prop
}

export default function ResultadoInfoPage({ status }: ResultadoInfoPageProps) {
    return (
        <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
          <CardContent sx={{ textAlign: 'center' }}>
            <Box sx={{ marginBottom: 2 }}>
              {status === 'success' ? (
                <CheckCircleOutlineIcon sx={{ fontSize: 80, color: 'green' }} />
              ) : (
                <ErrorOutlineIcon sx={{ fontSize: 80, color: 'red' }} />
              )}
            </Box>
            
            <Typography variant="h5" component="div">
              {status === 'success' ? 'Operación exitosa' : 'Error en la operación'}
            </Typography>
          </CardContent>
        </Card>
      );
  }

