import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Función para remover el spinner cuando React esté listo
const removeSpinner = () => {
  const spinner = document.getElementById('spinner');
  if (spinner) {
    spinner.style.display = 'none'; // O puedes usar spinner.remove() si prefieres eliminarlo completamente
  }
};

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

setTimeout(() => {
  removeSpinner();
}, 2000);
removeSpinner();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
