import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Typography, CssBaseline, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DrawerMenu from './components/DrawerMenu';
import { useMediaQuery, useTheme } from '@mui/material';
import { Routes, Route, BrowserRouter } from "react-router-dom";


import { ThemeProvider, createTheme } from '@mui/material/styles';

import logo from './images/logo-bcra.png';
import ScannFlow from './components/ScanFlow';
import LastScans from './components/LastScans';


const App: React.FC = () => {
  const [loading, setLoading] = useState(true); // Estado para simular la carga
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Usamos el hook de Material UI para detectar tamaños de pantalla
  const theme = createTheme({
    palette: {
      primary: {
        main: '#344968'
      }
    }
  });

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };


  if (loading) {
   // return <Spinner />; // Mostrar el spinner mientras la app está "cargando"
  }

  return (
    (<ThemeProvider theme={theme}>
      <BrowserRouter>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          {/* AppBar */}
          <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
            <Toolbar
              sx={{
                display: 'flex',
                justifyContent: 'center',  // Centra horizontalmente
                alignItems: 'center',      // Centra verticalmente
                position: 'relative',
              }}
            >
              {!isLargeScreen && ( // Solo muestra el botón de menú en pantallas pequeñas
                (<IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer}
                  sx={{ position: 'absolute', left: 10 }} // Posicionamos el botón de menú a la izquierda
                >
                  <MenuIcon />
                </IconButton>)
              )}

              <img src={logo} alt="logo" width={50} style={{ marginRight: 10 }} />
              <Typography variant="h6" noWrap>
                BCRA-INGRESO
              </Typography>
            </Toolbar>
          </AppBar>

          {/* Drawer */}
          <DrawerMenu
            open={drawerOpen || isLargeScreen} // Siempre abierto en pantallas grandes
            variant={isLargeScreen ? 'permanent' : 'temporary'} // Permanente en pantallas grandes, temporal en pequeñas
            onClose={toggleDrawer}
          />

          {/* Contenido Principal */}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: isLargeScreen ? `calc(100% - 240px)` : '100%', // Empuja el contenido en pantallas grandes
            }}
          >
            <Toolbar /> {/* Espacio para compensar el AppBar */}

            {/* Definición de rutas para cambiar el contenido */}
            <Routes>
              <Route path="/lastScans" element={<LastScans />} />
              <Route path="/scan" element={<ScannFlow />} />
              <Route path="*" element={<ScannFlow/>}/>

            </Routes>
          </Box>
        </Box>
      </BrowserRouter>
    </ThemeProvider>)
  );
};

export default App;
