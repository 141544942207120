import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, ListItemButton, Box, Typography, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';
import QRIcon from '@mui/icons-material/QrCode';
import ListIcon from '@mui/icons-material/List';

interface DrawerMenuProps {
  open: boolean;
  variant: 'permanent' | 'temporary';
  onClose: () => void;
}



  function icon(index:string):React.ReactElement<any>
  {
        switch  (index)
        {
            case 'qr': return <QRIcon />;
            case  'list': return <ListIcon />;
            default: return <ListIcon />;
        }
  }

  const DrawerMenu: React.FC<DrawerMenuProps> = ({ open, variant, onClose }) => {
    const menuItems = [
        { text: 'Escaneo', path: '/scan' ,icon:'qr' },
        { text: 'Listado', path: '/lastScans' ,icon:'list' }
    ];
  
  const appVersion = "v1.0.0";  // Define la versión de la app

  return (
    <Drawer

      variant={variant}
      open={open}
      onClose={onClose}
      sx={{
        width: variant === 'permanent' ? 240 : 'auto',
        '& .MuiDrawer-paper': {
          width: 240,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',  // Esto asegura que el contenido inferior esté en la parte inferior
        },
      }}
    >
      <Box>
      <Toolbar /> {/* Espacio para compensar el AppBar */}
      <List>
          {menuItems.map((item,index) => (
            <ListItem key={index}>
              <ListItemButton component={Link} to={item.path} onClick={onClose}>
                <ListItemIcon >
                    {icon(item.icon)}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}   
        </List>
      </Box>

      {/* Sección inferior con la versión de la app */}
      <Box sx={{ p: 2 }}>
        <Typography variant="body2" align="center" color="textSecondary">
          {`Versión ${appVersion}`}
        </Typography>
      </Box>
    </Drawer>
  );
};

export default DrawerMenu;
